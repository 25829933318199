import React from 'react'
import { Link } from 'react-router-dom'

const HeaderTopbar = () => {
    return (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-7 col-md-8 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><a class="email" href="mailto:sanquilstays@gmail.com"><i className="fi flaticon-send"></i>sanquilstays@gmail.com</a></li>
                                <li><a class="phone" href="tel:+919000056040"><i className="fi flaticon-phone-call"></i>+91 900 00 560 40</a></li>  
                                <li><a target="_blank" class="address" href="https://www.google.com/maps/place/Savi+Serenity/@17.3785796,78.2081873,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcbebb1e41e0fe1:0x3d550014bc342bbd!8m2!3d17.3785796!4d78.2081873!16s%2Fg%2F11s3ckj5lf"><i className="fi flaticon-placeholder"></i>Medipally Village, Moinabad, Hyderabad</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-4 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                {/* <li className="language">
                                    <select name="language" id="language">
                                        <option value="">English</option>
                                        <option value="">Arabic</option>
                                        <option value="">France</option>
                                    </select>
                                </li> */}
                                <li><a target="_blank" href="https://www.facebook.com/SaviSerenityHyd"><i className="fi flaticon-facebook-app-symbol"></i></a></li>
                                {/* <li><Link to="/"><i className="fi flaticon-twitter"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-linkedin"></i></Link></li> */}
                                <li><a target="_blank" href="https://www.instagram.com/saviserenity/"><i className="fi flaticon-instagram"></i></a></li>
                                {/* <li><Link to="/"><i className="fi flaticon-pinterest"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-youtube"></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;