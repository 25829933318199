import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.jpg'
import Services from '../../api/service'


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="footer" />
                                </div>
                                <p>Sanquil hospitalities specialized in providing accommodation, dining, entertainment, and leisure services to travelers and guests.</p>
                                <ul>
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/SaviSerenityHyd">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/saviserenity/">
                                            <i className="ti-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget s1">
                                <div className="widget-title">
                                    <h3>Services</h3>
                                </div>
                                <ul>
                                    {Services.slice(0,9).map((service, sitem) => (
                                        <li key={sitem}>{service.title}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Important Link</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/saviserenity">Savi serenity</Link></li>
                                    <li><Link onClick={ClickHandler} to="/tranquil">Tranquil</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><a target="_blank" class="address" href="https://www.google.com/maps/place/Savi+Serenity/@17.3785796,78.2081873,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcbebb1e41e0fe1:0x3d550014bc342bbd!8m2!3d17.3785796!4d78.2081873!16s%2Fg%2F11s3ckj5lf"><i className="fi flaticon-placeholder"></i>Medipally Village, Moinabad, Hyderabad</a></li>
                                        <li><a class="phone" href="tel:+919000056040"><i className="fi flaticon-phone-call"></i>+91 900 00 560 40</a></li>                                        
                                        <li><a class="email" href="mailto:sanquilstays@gmail.com"><i className="fi flaticon-send"></i>sanquilstays@gmail.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 by Sanquil Hospitalities. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;