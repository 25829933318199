import React from 'react'

const SectionTitleS2 = (props) => {
    return(
        <div className="wpo-section-title-s2">
            <h2>{props.MainTitle}</h2>
            <p>It is a long established fact that a vacation helps to relieve stress and boredom, gives us a change of scenery, provides us with adventure, and helps to bring us closer to the people in our lives.</p>
        </div>

    )
}

export default SectionTitleS2;