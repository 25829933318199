import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/aboutbg.jpg';

const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section">
            <div className="wpo-about-section-wrapper">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="wpo-about-img">
                            <img src={abimg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="wpo-about-content">
                            <div className="about-title">
                                <h2>About Us</h2>
                            </div>
                            <div className="wpo-about-content-inner">
                                <p>Nestled amidst the lush mango farms near the revered Chilukur Balaji Temple in Hyderabad, Sanquil Hospitalities offers a tranquil retreat that combines modern comfort with the serene beauty of nature. Specializing in farm stays and nature-inspired resorts, our properties provide guests with a rejuvenating escape in the heart of nature's bounty.</p>
                                <p>Our commitment to sustainability and community engagement is evident in every aspect of our properties. With spacious, rustic accommodations and modern amenities, our farm stays provide a perfect blend of comfort and natural charm. Guests can enjoy farm-to-table dining experiences at our nature-touch restaurants, featuring fresh, organic ingredients sourced from nearby farms.</p>
                                <p>A highlight of our offerings is the opportunity for guests to engage in farm cooking activities, where they can pick fresh produce and prepare meals under the guidance of our skilled chefs. This hands-on experience not only educates guests about sustainable farming practices but also adds a personal touch to their stay.</p>
                                <p>At Sanquil, we pride ourselves on delivering personalized service, ensuring that each guest's needs are met with warmth and attention to detail. As we continue to grow, our dedication to quality, sustainability, and enriching the local community remains unwavering, making us the premier choice for a peaceful and immersive retreat amidst the mango farms near the Chilukur Balaji Temple.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;