import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import api from '../../api'
import simg1 from '../../images/rmservice.jpg'
import simg2 from '../../images/amenities.jpg'

import rv1 from '../../images/testimonial/feedback.jpg'
import rv2 from '../../images/room/r2.jpg'
import RoomSidebar from './RoomSidebar'

const RoomDetails = (props) => {
    const pathName = useLocation().pathname;
    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const productsArray = api();
    const Allproduct = productsArray

    const [product, setProduct] = useState({});

    useEffect(() => {
        let filteredProducts = Allproduct.filter(Allproduct => Allproduct.id === pathName);
        setProduct(filteredProducts[0] || {});
    }, []);

    const item = product;
    return (
        <div className="Room-details-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <div className="room-description">
                            <div className="room-title">
                                <h2>Description</h2>
                            </div>
                            {
                                pathName === '/saviserenity' ? <>
                                    <p className="p-wrap">Savi Serenity Resorts & Mango Farm Stay is an oasis of calm amid a verdant mango grove. The resort is the ideal escape for nature enthusiasts and those searching for tranquillity, as it provides the best of both worlds. Guests at Savi Serenity Resorts have access to several state-of-the-art facilities, including climate-controlled rooms, wireless Internet access, a swimming pool, and a fully-stocked fitness centre. Nature hikes, bird viewing, and cycling are just a few outdoor pursuits that may be enjoyed at this resort. In addition, the on-site restaurant serves a variety of delicious options.</p>
                                    <p>Discover the perfect blend of tropical luxury and natural charm at our resort nestled within lush mango farms. Wake up to the sweet scent of ripe mangoes in the air as you relax in our beautifully appointed accommodations. Indulge in farm-to-table dining experiences, where succulent mango-infused dishes tantalize your taste buds. Unwind amidst verdant orchards, savoring moments of tranquility under swaying mango trees or embark on guided tours to explore the vibrant surroundings. Escape to our resort for a rejuvenating retreat amidst the serenity of mango-scented paradise.</p>
                                </> : <>
                                    <p className="p-wrap">Sanquil Resorts & Mango Farm Stay is an oasis of calm amid a verdant mango grove. The resort is the ideal escape for nature enthusiasts and those searching for tranquillity, as it provides the best of both worlds. Guests at Savi Serenity Resorts have access to several state-of-the-art facilities, including climate-controlled rooms, wireless Internet access, a swimming pool, and a fully-stocked fitness centre. Nature hikes, bird viewing, and cycling are just a few outdoor pursuits that may be enjoyed at this resort. In addition, the on-site restaurant serves a variety of delicious options.</p>
                                    <p>Discover the perfect blend of tropical luxury and natural charm at our resort nestled within lush mango farms. Wake up to the sweet scent of ripe mangoes in the air as you relax in our beautifully appointed accommodations. Indulge in farm-to-table dining experiences, where succulent mango-infused dishes tantalize your taste buds. Unwind amidst verdant orchards, savoring moments of tranquility under swaying mango trees or embark on guided tours to explore the vibrant surroundings. Escape to our resort for a rejuvenating retreat amidst the serenity of mango-scented paradise.</p>
                                </>
                            }

                        </div>
                        <div className="room-details-service">
                            <div className="row">
                                <div className="room-details-item">
                                    <div className="row">
                                        <div className="col-md-5 col-sm-5">
                                            <div className="room-d-text">
                                                <div className="room-title">
                                                    <h2>Amenities</h2>
                                                </div>
                                                <ul>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Refrigerator and water</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Air Conditioner Facilities</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Sofa set</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">TV</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Kettle</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Wi-fi</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-7">
                                            <div className="room-d-img">
                                                <img src={simg1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-7">
                                            <div className="room-d-img">
                                                <img src={simg2} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-5">
                                            <div className="room-d-text2">
                                                <div className="room-title">
                                                    <h2>Room Services</h2>
                                                </div>
                                                <ul>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Daily Cleaning</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Special Swimming Pool</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Free Parking</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Free-to-use smartphone </Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Free Wifi</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">EPSON Projector</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">JBL Party Box Speaker</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Outside Bed Support</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Camp Fire</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Barbeque Stand</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/room-single/1">Ambience Lighting</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-area">
                            <div className="room-title">
                                <h2>Pricing Plans (Without Food)</h2>
                            </div>
                            <div className="pricing-table">
                                <table className="table-responsive pricing-wrap">
                                    <thead>
                                        <tr>
                                            <th>Mon</th>
                                            <th>Tue</th>
                                            <th>Wed</th>
                                            <th>Thu</th>
                                            <th>Fri</th>
                                            <th>Sat</th>
                                            <th>Sun</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rs {product.priceWithoutFoodWeekDay}</td>
                                            <td>Rs {product.priceWithoutFoodWeekDay}</td>
                                            <td>Rs {product.priceWithoutFoodWeekDay}</td>
                                            <td>Rs {product.priceWithoutFoodWeekDay}</td>
                                            <td>Rs {product.priceWithoutFoodWeekDay}</td>
                                            <td>Rs {product.priceWithoutFoodWeekEnd}</td>
                                            <td>Rs {product.priceWithoutFoodWeekEnd}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="map-area">
                                <iframe
                                    src={product.embedLink}></iframe>
                            </div>
                        </div>
                        <div className="room-review">
                            <div className="room-title">
                                <h2>Resort Reviews</h2>
                            </div>
                            {
                                product.reviews?.map(review => {
                                    return <div className="review-item">
                                        <div className="review-img">
                                            <img src={rv1} alt="" />
                                        </div>
                                        <div className="review-text">
                                            <div className="r-title">
                                                <h2>{review.user}</h2>
                                                <ul>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                </ul>
                                            </div>
                                            <p>{review.comment} </p>
                                        </div>
                                    </div>
                                })
                            }


                        </div>
                        {/* <div className="add-review">
                            <div className="room-title">
                                <h2>Add Review</h2>
                            </div>
                            <div className="wpo-blog-single-section review-form ">
                                <div className="give-rat-sec">
                                    <p>Your rating *</p>
                                    <div className="give-rating">
                                        <label>
                                            <input type="radio" name="stars" value="1" />
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" value="2" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" value="3" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" value="4" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" value="5" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="review-add">
                                    <div className="comment-respond">
                                        <form id="commentform" className="comment-form" onSubmit={SubmitHandler}>
                                            <div className="form-inputs">
                                                <input placeholder="Your Name*" type="text" />
                                                <input placeholder="Your Email*" type="email" />
                                            </div>
                                            <div className="form-textarea">
                                                <textarea id="comment" placeholder="Your Review"></textarea>
                                            </div>
                                            <div className="form-check">
                                                <div className="shipp pb">
                                                    <input type="checkbox" id="c2" name="cc" />
                                                    <label htmlFor="c2"><span></span>Save my name, email, and website in
                                                        this browser for the next time I comment.</label>
                                                </div>
                                            </div>
                                            <div className="form-submit">
                                                <input id="submit" value="Submit Now" type="submit" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <RoomSidebar />
                </div>
            </div>
        </div>
    )
}

export default RoomDetails;