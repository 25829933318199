import React from 'react'
import { Link } from 'react-router-dom'
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import hero1 from '../../images/slider/savi-slide.jpg'
import hero2 from '../../images/slider/tranquil-slide.jpg'
//import hero3 from '../../images/slider/slide-3.jpg'


const sliderItems = [
    {
        sImg: hero1,
        text: 'Savi Serenity Resorts is a luxury getaway located just outside Hyderabad, nestled in picturesque farming areas. Known for excellent service, the resort offers comfortable rooms, delicious food, and relaxing activities. Committed to the environment and local communities, Savi Serenity Resorts provides a perfect blend of comfort and rural charm.',
        parklink: '/saviserenity'
    },
    {
        sImg: hero2,
        text: 'Tranquil Resort is a luxury retreat located just outside Hyderabad, nestled beside the renowned Savi Serenity Resorts in picturesque farming landscapes. Offering the same exceptional services as Savi Serenity, Tranquil Resort features comfortable accommodations, delectable cuisine, and a variety of relaxing activities. Dedicated to environmental sustainability and supporting local communities, Tranquil Resort provides a perfect blend of comfort and rustic charm.',
        parklink: '/tranquil'
    }
]

const Hero = (props) => {
    return (
        <section className="wpo-hero-slider">
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        loop={true}
                        speed={1800}
                        parallax={true}
                        navigation
                    >
                        {sliderItems.map((item, slr) => (
                            <SwiperSlide key={slr}>
                                <div className="swiper-slide" style={{ backgroundImage: `url(${item.sImg})` }}>
                                    <div className="slide-inner slide-bg-image">
                                        <div className="container-fluid">
                                            <div className="slide-content">
                                                <div data-swiper-parallax="300" className="slide-title">
                                                    <p>{item.text}</p>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div data-swiper-parallax="500" className="slide-btns">
                                                    <Link to={item.parklink} className="theme-btn">Know More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        ...
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Hero;