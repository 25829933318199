import React from 'react'
import { Link } from 'react-router-dom'
import PrImg1 from '../../images/pricing/basic.jpg'
import PrImg2 from '../../images/pricing/standard.jpg'
import PrImg3 from '../../images/pricing/luxury.jpg'
import SectionTitleS2 from '../SectionTitleS2'


const Pricing = [
    {
        prImg: PrImg1,
        pakage: 'Silver Events',
        prices: ['35,000'],
        subs: 'Day',
        l1: 'Upto 50 persons capacity',
        l2: 'Night staying allowed for 11 persons'
    },
    {
        prImg: PrImg2,
        pakage: 'Golden Events',
        prices: ['50,000'],
        subs: 'Day',
        l1: 'Upto 70 persons capacity',
        l2: 'Night staying allowed for 25 persons'
    },
    {
        prImg: PrImg2,
        pakage: 'Platinum Events',
        prices: ['70,000'],
        subs: 'Day',
        l1: 'Upto 150 - 200 persons capacity',
        l2: 'Night staying allowed for 25 persons'
    }
]

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const PricingSection = (props) => {

    return (
        <section className="wpo-pricing-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <SectionTitleS2 MainTitle={'Our Awesome Packages'} />
                    </div>
                </div>
                <div className="wpo-pricing-wrap">
                    <div className="row">
                        {Pricing.map((pricing, pitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={pitem}>
                                <div className="wpo-pricing-item">
                                    <div className="wpo-pricing-top">
                                        <div className="wpo-pricing-img">
                                            <img src={pricing.prImg} alt="" />
                                        </div>
                                        <div className="wpo-pricing-text">
                                            <h4>{pricing.pakage}</h4>
                                            {
                                                pricing.prices.map(price => {
                                                    return <h6>Rs {price}</h6>
                                                })
                                            }
                                            <span>Per {pricing.subs}</span>
                                        </div>
                                    </div>
                                    <div className="wpo-pricing-bottom">
                                        <div className="wpo-pricing-bottom-text">
                                            <ul>
                                                <li>{pricing.l1}</li>
                                                <li>{pricing.l2}</li>
                                                <li>{pricing.l4}</li>
                                                <li>{pricing.l3}</li>
                                            </ul>
                                            <Link onClick={ClickHandler} className="theme-btn" to="/pricing">Book Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default PricingSection;