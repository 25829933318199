import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useParams, useLocation } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar'
import { connect } from "react-redux";
import api from "../../api";
import Footer from '../../components/footer';
import Logo from '../../images/logo2.jpg'
import Rooms from './rooms';
import RoomDetails from './RoomDetails';
import Newslatter from '../../components/Newslatter/Newslatter';


const RoomSinglePage = (props) => {
    const lc = useLocation()
    const pathName = lc.pathname;


    const productsArray = api();
    const Allproduct = productsArray

    const [product, setProduct] = useState({});

    useEffect(() => {
        setProduct(Allproduct.filter(Allproduct => Allproduct.id === pathName))
    }, []);

    const item = product[0];

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={item ? item.title : null} pagesub={'Room'} />
            <div className="room-details-section">
                {item ?
                    <div className="room-details-inner">
                        <div className="wpo-hotel-details-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="wpo-hotel-details-wrap">
                                            <div className="wpo-hotel-details-area">
                                                <form className="clearfix">
                                                    <div className="details-sub rm-details">
                                                        <span>Rooms</span>
                                                        <h2>{item.bedroom} Bed Rooms/ {item.livingroom} Living Rooms</h2>
                                                    </div>
                                                    <div className="details-sub small-details">
                                                        <span>Room Size</span>
                                                        <h2>{item.sqm} Sqft</h2>
                                                    </div>
                                                    <div className="details-sub small-details">
                                                        <span>Occupancy</span>
                                                        <h2>{item.capacity}</h2>
                                                    </div>
                                                    <div className="details-sub small-details">
                                                        <span>Bathroom(s)</span>
                                                        <h2>{item.bathroom}</h2>
                                                    </div>
                                                    <div className="details-sub price-details">
                                                        <span>Price</span>
                                                        <h5><span>Rs {item.priceWithFoodWeekDay}</span> / Day (Mon - Fri, With Food)</h5>
                                                        <h5><span>Rs {item.priceWithoutFoodWeekDay}</span> / Day (Mon - Fri, Without Food)</h5>
                                                        <h5><span>Rs {item.priceWithFoodWeekEnd}</span> / Day (Sat - Sun, With Food)</h5>
                                                        <h5><span>Rs {item.priceWithoutFoodWeekEnd}</span> / Day (Sat - Sun, Without Food)</h5>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Rooms item={item}/>
                        <RoomDetails/>
                    </div>
                : null}
            </div>
            <Newslatter nClass={'section-bg'}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
    }
};

export default connect(mapStateToProps)(RoomSinglePage);
